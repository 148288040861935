import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "../components/Link";
import Layout from "../components/Layout";

import ParkaPrimoInnenjackeFront from "../Garments/ParkaPrimoInnenjacke/ParkaPrimoInnenjackeFront";
import ParkaPrimoAussenjackeFront from "../Garments/ParkaPrimoAussenjacke/ParkaPrimoAussenjackeFront";
import PolarFront from "../Garments/Polar/PolarFront";
import {COLORS} from "../utils/colors";
import GiletPrimoRVFront from "../Garments/GiletPrimoRV/GiletPrimoRVFront";
import DamenkasackPrimoFront from "../Garments/DamenkasackPrimo/DamenkasackPrimoFront";
import GilletPrimoKnopfenFront from "../Garments/GilletPrimoKnopfen/GilletPrimoKnopfenFront";
import FleecejackePrimoFront from "../Garments/FleecejackePrimo/FleecejackePrimoFront";
import ClassicoJackeFront from "../Garments/ClassicoJacke/ClassicoJackeFront";
import TrendGiletFront from "../Garments/TrendGilet/TrendGiletFront";
import SoftshelljackePrimoPlusFront from "../Garments/SoftshelljackePrimoPlus/SoftshelljackePrimoPlusFront";
import SoftshelljackePrimoFront from "../Garments/SoftshelljackePrimo/SoftshelljackePrimoFront";
import BerufsmantelPrimoFront from "../Garments/BerufsmantelPrimo/BerufsmantelPrimoFront";

const useStyles = makeStyles((theme) => ({
  heroButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    minHeight: "280px",
    maxWidth: "300px",
    width: "100%",
    align: "center",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [
  {
    id: 1,
    heading: "Parka Primo Aussenjacke",
    type: "Wind- und wasserdicht, kombinierbar.",
    url: "/parka-primo-aussenjacke",
    img: (
      <ParkaPrimoAussenjackeFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 2,
    heading: "Parka Primo Innenjacke",
    type: "Mit abnehmbaren Ärmeln, kombinierbar.",
    url: "/parka-primo-innenjacke",
    img: (
      <ParkaPrimoInnenjackeFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 3,
    heading: "Fleecejacke Primo",
    type: "",
    url: "/fleecejacke-primo",
    img: (
      <FleecejackePrimoFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 4,
    heading: "Fleecejacke Polar",
    type: "",
    url: "/polar",
    img: (
      <PolarFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 5,
    heading: "Softshelljacke Primo Plus",
    type: "",
    url: "/softshelljacke-plus",
    img: (
      <SoftshelljackePrimoPlusFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 6,
    heading: "Softshelljacke Primo",
    type: "",
    url: "/softshelljacke",
    img: (
      <SoftshelljackePrimoFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 7,
    heading: "Berufsmantel Primo",
    type: "",
    url: "/berufsmantel",
    img: (
      <BerufsmantelPrimoFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 8,
    heading: "Classico Jacke",
    type: "",
    url: "/classico-jacke",
    img: (
      <ClassicoJackeFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 9,
    heading: "Gilet Trend",
    type: "",
    url: "/trend-gilet",
    img: (
      <TrendGiletFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 10,
    heading: "Damenkasack Primo",
    type: "",
    url: "/damenkasack",
    img: (
      <DamenkasackPrimoFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 11,
    heading: "Gilet Primo mit Knöpfen",
    type: "",
    url: "/gilet-primo-knopfen",
    img: (
      <GilletPrimoKnopfenFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
  {
    id: 12,
    heading: "Gilet Primo mit RV",
    type: "",
    url: "/gilet-primo-rv",
    img: (
      <GiletPrimoRVFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.white.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.black.value}
        preview={true}
      />
    ),
  },
];

export default function Jackets() {
  const classes = useStyles();

  return (
    <Layout>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button variant="contained" color="primary">
                  Zurück
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary">
                  Hose
                </Button>
              </Grid>
            </Grid>
          </div> */}
          <Grid container spacing={1}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <Link to={card.url}>{card.img}</Link>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>{card.type}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button color="secondary" variant="contained" size="small">
                      <Link style={{color: "white"}} to={card.url}>
                        Wählen Sie das Farbschema
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </Layout>
  );
}
